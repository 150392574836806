export function removePrivate(data) {
  let publicData = { ...data }
  // Remap Specialty to SpecialtyGrouping. The Add/Update methods
  // will take Specialty, but the GET returns it as SpecialtyGrouping.
  publicData.Specialty = publicData.SpecialityGrouping
  const REMOVE = [
    'SpecialityGrouping',
    'DEA',
    'DEAExpirationDate',
    'StateLicense',
    'StateLicenseExpirationDate',
    'StateLicenseStateIssued',
    'NpiNumber',
    'NumDiagnosedPatients'
  ]
  REMOVE.forEach(field => {
    delete publicData[field]
  })
  return publicData
}
