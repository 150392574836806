import React from 'react'
import PropTypes from 'prop-types'

import SiteLink from '@components/SiteLink'
import LeavingSiteModal from '@components/leaving-site-modal/leaving-site-modal.component'
import EmailFinderComponent from '@components/locate-sleep-specialist/email-finder/email-finder.component'
import SearchBoxContainer from '../search-box/search-box.component'
import { PROFILE_PAGES } from '@components/profile/profile.component'

import './search-profile.component.scss'

class SearchProfileComponent extends React.Component {
  constructor(props) {
    super(props)

    this.handleShowModalClicked = this.handleShowModalClicked.bind(this)
    this.handleHideModal = this.handleHideModal.bind(this)

    this.state = {
      search: {},
      showLeavingSiteModal: false,
      href: '',
      stateStatus: PROFILE_PAGES.searchProfile
    }
  }

  runSearch() {
    this.props.setSearch(this.state.search)
    this.props.setCurrentPage(PROFILE_PAGES.selectProfile);
  }

  newLocation(res) {
    this.setState({ search: res })
  }

  handleInputChange(label, value) {
    this.setState({
      [label]: value
    })
  }

  handleHideModal() {
    this.setState({
      showLeavingSiteModal: false,
    })
  }

  handleShowModalClicked() {
    this.setState({
      showLeavingSiteModal: true,
    })
  }

  openLink(link) {
    this.setState(() => {
      return { href: link }
    }, this.handleShowModalClicked())
  }

  render() {
    return (
      <div className="search-profile">
        <div className="primary-container">
          <h1 className="main-page-header">Update Your Information or Remove Your Profile</h1>
          <p className="page-comment">
            If you are already listed as a Sleep Specialist in our directory and wish to change your name, address, or
            contact information, or need to remove your listing, use the search box below to find your listing, which
            will include instructions on how to make the changes you want.
          </p>
          <p className="page-comment">
            If you are not a healthcare professional,{' '}
            <span className="link-text" onClick={() => this.openLink('https://www.morethantired.com')}>
              click here
            </span>.
          </p>
        </div>
        <div className="primary-container search-form">
          <p className="form-comment">Search for your listing:</p>
          <SearchBoxContainer
            runSearch={() => {
              this.runSearch()
            }}
            newLocation={res => {
              this.newLocation(res)
            }}
          />
          <p className="search-comment">
            If you do not have a profile and would like to create one, please{' '}
            <SiteLink className="link-text" to="/stay-connected/join-the-sleep-specialist-directory/">
              click here
            </SiteLink>.
          </p>
        </div>
        <EmailFinderComponent />
        <LeavingSiteModal
          isShowModal={this.state.showLeavingSiteModal}
          onClose={this.handleHideModal}
          href={this.state.href}
        />
      </div>

    )
  }
}

SearchProfileComponent.propTypes = {
  setCurrentPage: PropTypes.func.isRequired,
  setSearch: PropTypes.func.isRequired,
}

export default SearchProfileComponent

