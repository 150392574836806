import React from 'react'
import PropTypes from 'prop-types'
import Radio from '@material-ui/core/Radio'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormControl from '@material-ui/core/FormControl'
import ReCAPTCHA from 'react-google-recaptcha'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'

import { removePrivate } from '@utils/profile.util'
import SiteLink from '@components/SiteLink'
import EmailFinderComponent from '@components/locate-sleep-specialist/email-finder/email-finder.component'
import stateList from '@components/states'
import { PROFILE_PAGES } from '../profile.component'
import { specialityList, professionalDesignationList } from '../profile.util'
import { ValidatorForm, TextValidator, SelectValidator, CheckboxValidator, RadioValidator } from '@components/validators'
import LeavingSiteModal from '@components/leaving-site-modal/leaving-site-modal.component'
import { trackGa } from '@components/GoogleTracking'
import CommonModal from '@components/common-modal/common-modal.component'
import config from "@utils/config"

import './update-profile.component.scss'

const ReCAPTCHAKey = `${config.REACT_APP_GOOGLE_RECAPTCHA}`

class UpdateYourProfileComponent extends React.Component {
  constructor(props) {
    super(props)
    const publicProfile = removePrivate(props.profile)
    this.state = {
      First: '',
      Middle: '',
      Last: '',
      ProfessionalDesignation: '',
      Specialty: '',
      GroupPracticeName: '',
      WebSite: '',
      Address1: '',
      Address2: '',
      City: '',
      State: '',
      Country: 'Untited States',
      Zip: '',
      Lat: '',
      Lng: '',
      Phone: '',
      Fax: '',
      Email: '',
      StateLicense: '',
      StateLicenseExpirationDate: '',
      StateLicenseStateIssued: '',
      DEA: '',
      DEAExpirationDate: '',
      NpiNumber: '',
      PediatricSpecialist: '',
      isPatient: true, //TODO: this is actually a phase 3 attribute
      NumDiagnosedPatients: '',
      isCertify: '',
      isAccept: '',
      isYes: '',
      isNo: '',
      loading: false,
      ...publicProfile,
      showLeavingSiteModal: false,
      href: '',
      canContinue: false,
      showSuccessModal: false
    }

    this.onSubmitForm = this.onSubmitForm.bind(this)
    this.handleShowModalClicked = this.handleShowModalClicked.bind(this)
    this.handleHideModal = this.handleHideModal.bind(this)
  }

  allowContinue() {
    this.setState({ canContinue: true })
  }
  disableContinue() {
    this.setState({ canContinue: false })
  }

  handleHideModal() {
    this.setState({
      showLeavingSiteModal: false,
    })
  }

  handleShowModalClicked() {
    this.setState({
      showLeavingSiteModal: true,
    })
  }

  openLink(link) {
    this.setState(() => {
      return { href: link }
    }, this.handleShowModalClicked())
  }

  handleInputChange(label, event) {
    const value = event.target ? event.target.value : event
    this.setState({
      [label]: value,
    })
  }

  onSubmitForm(event) {
    trackGa('update,	click,	submit information - update')
    event.preventDefault()
    this.setState({ ...this.state, loading: true })
    let postData = { ...this.state }
    const address = this.state.Address1 + ', ' + this.state.City + ', ' + this.state.State + ', ' + this.state.Zip
    const google = window.google
    const geocoder = new google.maps.Geocoder()

    geocoder.geocode(
      {
        address: address,
      },
      (geoData, geoStatus) => {
        if (geoStatus == google.maps.GeocoderStatus.OK) {
          (postData.Lat = geoData[0].geometry.location.lat()), (postData.Lng = geoData[0].geometry.location.lng())
        }

        fetch(`${config.REACT_APP_API_HOST}/physician-finder.php/UpdatePhysician`, {
          method: 'POST',
          body: JSON.stringify(postData),
        })
          .then((res) => res.json())
          .then(() => {
            this.setState({ ...this.state, loading: false, showSuccessModal: true })
          })
      }
    )
  }

  handleFocus(e) {
    e.target.classList.add('focused-input')
  }

  handleBlur(e) {
    e.target.classList.remove('focused-input')
  }

  render() {
    return (
      <div className="update-your-profile">
        <div className="primary-container">
          <h1 className="main-page-header">Update Your Profile in the Sleep Specialist Directory</h1>
          <p className="page-comment">
            The form below reflects the information currently visible in your Sleep Specialist profile. Please edit any
            fields that require changes.
          </p>
          <div className="form-comments">
            <ol>
              <li className="form-comment">
                We will send you an email to the address we have on file to validate you are authorized to make this
                update.
              </li>
              <li className="form-comment">
                Click on the confirmation link included within the email to confirm your changes.
              </li>
            </ol>
            <p className="form-comment">
              Once this form is complete, we will validate the information you submitted and send you an email as soon
              as your updates have been confirmed. To remove your information from the Sleep Specialist directory,
              please visit the{' '}
              <a
                className="link-color-text"
                onClick={() => {
                  this.props.setCurrentPage(PROFILE_PAGES.removeProfile);
                }}
              >
                Remove Profile
              </a>&nbsp;page. If you have any questions, please contact us at{' '}
              <a className="link-color-text" href={'mailto:support@narcolepsylink.com'}>
                support@narcolepsylink.com
              </a>
              .
            </p>
          </div>
        </div>
        <div className="primary-container">
          <ValidatorForm onSubmit={this.onSubmitForm} instantValidate={false}>
            <div className="form-row">
              <div className="input-group">
                <TextValidator
                  name="First"
                  className="input-normal"
                  label="First Name*"
                  value={this.state.First}
                  onChange={(val) => this.handleInputChange('First', val)}
                  validators={['required', 'isName']}
                  errorMessages={['Please fill out this field', 'No numbers or special characters in this field']}
                />
                <TextValidator
                  name="Middle"
                  className="input-medium"
                  label="Middle Initial"
                  value={this.state.Middle}
                  onChange={(val) => this.handleInputChange('Middle', val)}
                  validators={['isName']}
                  errorMessages={['No numbers or special characters in this field']}
                />
              </div>
              <div className="input-group">
                <TextValidator
                  name="Last"
                  className="input-normal"
                  label="Last Name*"
                  value={this.state.Last}
                  onChange={(val) => this.handleInputChange('Last', val)}
                  validators={['required', 'isName']}
                  errorMessages={['Please fill out this field', 'No numbers or special characters in this field']}
                />
                <div className=" input-normal">
                  <SelectValidator
                    name="ProfessionalDesignation"
                    className="input-normal"
                    value={this.state.ProfessionalDesignation}
                    onChange={(e) => this.handleInputChange('ProfessionalDesignation', e.target.value)}
                    label="Professional Designation*"
                    validators={['required']}
                    errorMessages={['Please fill out this field']}
                  >
                    <option value="" disabled>
                      Professional Designation*
                    </option>
                    {professionalDesignationList.map((item) => (
                      <option key={item.id} value={item.id}>
                        {item.name}
                      </option>
                    ))}
                  </SelectValidator>
                </div>
              </div>
            </div>
            <div className="form-row">
              <div className="input-group">
                <div className=" input-normal">
                  <SelectValidator
                    name="Specialty"
                    className="input-normal"
                    value={this.state.Specialty}
                    onChange={(e) => this.handleInputChange('Specialty', e.target.value)}
                    label="Specialty*"
                    validators={['required']}
                    errorMessages={['Please fill out this field']}
                  >
                    <option value="" disabled hidden selected>
                      Speciality*
                    </option>
                    {specialityList.map((item) => (
                      <option key={item.id} value={item.id}>
                        {item.name}
                      </option>
                    ))}
                  </SelectValidator>
                </div>
                <TextValidator
                  name="GroupPracticeName"
                  className="input-normal"
                  label="Name of Practice"
                  value={this.state.GroupPracticeName}
                  onChange={(val) => this.handleInputChange('GroupPracticeName', val)}
                  validators={['isName']}
                  errorMessages={['No numbers or special characters in this field']}
                  autoComplete="disabled"
                />
              </div>
              <TextValidator
                name="Website"
                className="input-normal"
                label="Physician/Practice Website"
                value={this.state.Website}
                onChange={(val) => this.handleInputChange('Website', val)}
                validators={['isWebsite']}
                errorMessages={['Please enter a valid web address']}
              />
            </div>
            <div className="form-row">
              <div className="input-group">
                <TextValidator
                  name="Address1"
                  className="input-normal"
                  label="Address 1*"
                  value={this.state.Address1}
                  onChange={(val) => this.handleInputChange('Address1', val)}
                  validators={['required']}
                  errorMessages={['Please fill out this field']}
                />
                <TextValidator
                  name="Address2"
                  className="input-normal"
                  label="Address 2"
                  value={this.state.Address2}
                  onChange={(val) => this.handleInputChange('Address2', val)}
                />
              </div>
              <div className="input-group">
                <TextValidator
                  name="City"
                  className="input-normal"
                  label="City*"
                  value={this.state.City}
                  onChange={(val) => this.handleInputChange('City', val)}
                  validators={['required', 'isAlphanumeric']}
                  errorMessages={['Please fill out this field', 'Entry is not valid']}
                />
                <SelectValidator
                  name="State"
                  className="input-small"
                  value={this.state.State}
                  onChange={(e) => this.handleInputChange('State', e.target.value)}
                  validators={['required']}
                  errorMessages={['Please fill out this field']}
                  label="State*"
                >
                  <option value="" disabled>
                    State*
                  </option>
                  {stateList.map((item) => (
                    <option key={item.abbreviation} value={item.abbreviation}>
                      {item.abbreviation}
                    </option>
                  ))}
                </SelectValidator>
                <TextValidator
                  name="Zip"
                  className="input-small-medium"
                  label="ZIP*"
                  value={this.state.Zip}
                  onChange={(val) => this.handleInputChange('Zip', val)}
                  validators={['required', 'isZip']}
                  errorMessages={['Please fill out this field', 'ZIP code is not valid']}
                />
              </div>
            </div>
            <div className="form-row">
              <div className="input-group">
                <TextValidator
                  name="Phone"
                  className="input-normal"
                  label="Office Phone*"
                  value={this.state.Phone}
                  onChange={(val) => this.handleInputChange('Phone', val)}
                  validators={['required', 'isPhoneNumber']}
                  errorMessages={['Please fill out this field', 'Phone number is invalid']}
                />
                <TextValidator
                  name="Fax"
                  className="input-normal"
                  label="FAX"
                  value={this.state.Fax}
                  onChange={(val) => this.handleInputChange('Fax', val)}
                  validators={['isPhoneNumber']}
                  errorMessages={['Phone number is invalid']}
                />
              </div>
              <TextValidator
                name="Email"
                type="email"
                className="input-large"
                label="Email*"
                onChange={(val) => this.handleInputChange('Email', val)}
                value={this.state.Email}
                validators={['required', 'isEmail']}
                errorMessages={['Please fill out this field', 'Email is not valid']}
              />
            </div>
            <p className="form-header">Confirm Your Identity</p>
            <p className="item-comment">
              In order to update your profile in the Sleep Specialist directory, you must confirm your identity by
              entering your State License and DEA numbers. (This information is private and will not be shared with
              anyone, including website users.)
            </p>
            <div className="form-row confirm-identity">
              <div className="input-group">
                <TextValidator
                  name="StateLicense"
                  className="input-normal"
                  label="State License Number*"
                  value={this.state.StateLicense}
                  onChange={(val) => this.handleInputChange('StateLicense', val)}
                  validators={['required', 'isNumbers']}
                  errorMessages={['Please fill out this field', 'Only numbers allowed']}
                  autoComplete="disabled"
                />
                <TextValidator
                  name="StateLicenseExpirationDate"
                  className="input-normal"
                  label="License Expiration Date*"
                  pattern="((0[1-9]|1[0-2])\/(0[1-9]|[12]\d|3[01])\/([1]\d{3}|20\d{2}))"
                  placeholder="MM/DD/YYYY"
                  value={this.state.StateLicenseExpirationDate}
                  onChange={(val) => this.handleInputChange('StateLicenseExpirationDate', val)}
                  validators={['required', 'isDate', 'isValidDate']}
                  errorMessages={[
                    'Please fill out this field',
                    'Please enter a valid date',
                    'Please enter a valid date',
                  ]}
                  autoComplete="disabled"
                />
              </div>
              <div className=" input-medium">
                <SelectValidator
                  name="StateIssued"
                  className="input-medium"
                  value={this.state.StateLicenseStateIssued}
                  onChange={(val) => this.handleInputChange('StateLicenseStateIssued', val)}
                  label="State Issued*"
                  validators={['required']}
                  errorMessages={['Please fill out this field']}
                  autoComplete="disabled"
                >
                  <option value="" disabled>
                    State Issued*
                  </option>
                  {stateList.map((item) => (
                    <option key={item.abbreviation} value={item.abbreviation}>
                      {item.name}
                    </option>
                  ))}
                </SelectValidator>
              </div>
            </div>
            <div className="form-row">
              <div className="input-group">
                <TextValidator
                  name="DEA"
                  className="input-normal"
                  label="DEA Number*"
                  value={this.state.DEA}
                  onChange={(val) => this.handleInputChange('DEA', val)}
                  validators={['required', 'isDea']}
                  errorMessages={['Please fill out this field', 'Please enter a valid DEA number']}
                />
                <TextValidator
                  name="DEAExpirationDate"
                  className="input-normal"
                  label="DEA Expiration Date*"
                  value={this.state.DEAExpirationDate}
                  placeholder="MM/DD/YYYY"
                  onChange={(val) => this.handleInputChange('DEAExpirationDate', val)}
                  validators={['required', 'isDate', 'isValidDate']}
                  errorMessages={[
                    'Please fill out this field',
                    'Please enter a valid date',
                    'Please enter a valid date',
                  ]}
                />
              </div>
            </div>
            <div className="form-row">
              <TextValidator
                name="NpiNumber"
                className="input-normal"
                label="NPI Number"
                value={this.state.NpiNumber}
                onChange={(val) => this.handleInputChange('NpiNumber', val)}
                validators={['isNumbers']}
                errorMessages={['Only numbers allowed']}
                autoComplete="disabled"
              />
            </div>
            <div className="form-row option-group">
              <label className="item-comment">Do you treat pediatric patients?*</label>
              <FormControl>
                <RadioValidator
                  name="PediatricSpecialist"
                  value={this.state.PediatricSpecialist}
                  validators={['required']}
                  errorMessages={['Please fill out this field']}
                  onClick={(e) => this.handleInputChange('PediatricSpecialist', e)}
                >
                  <FormControlLabel
                    className="radio-input"
                    value={'true'}
                    control={<Radio color="default" checked={this.state.PediatricSpecialist === 'true'} />}
                    label={<label>Yes</label>}
                  />
                  <FormControlLabel
                    className="radio-input"
                    value={'false'}
                    control={<Radio color="default" checked={this.state.PediatricSpecialist === 'false'} />}
                    label={<label>No</label>}
                  />
                </RadioValidator>
              </FormControl>
            </div>
            <div className="form-row last-count">
              <p className="item-comment">
                How many patients have you diagnosed with narcolepsy in the past 24 months?*
              </p>
              <TextValidator
                name="NumDiagnosedPatients"
                className="input-number"
                value={this.state.NumDiagnosedPatients}
                onChange={(val) => this.handleInputChange('NumDiagnosedPatients', val)}
                validators={['required', 'isPositiveInt']}
                errorMessages={['Please fill out this field', 'Only numbers allowed']}
              />
            </div>
            <div className="form-row checkbox-group">
              <FormControlLabel
                control={
                  <CheckboxValidator
                    name="certify"
                    validators={['required', 'isTrue']}
                    errorMessages={['Please check this box if you want to proceed', 'Please check this box if you want to proceed']}
                    value={this.state.isCertify}
                    checked={this.state.isCertify}
                    onChange={(e) => this.handleInputChange('isCertify', e.target.checked)}
                  />
                }
                label="I certify that I have been trained to diagnose and differentiate sleep disorders."
              />

              <FormControlLabel
                control={
                  <CheckboxValidator
                    name="accept"
                    validators={['required', 'isTrue']}
                    errorMessages={['Please check this box if you want to proceed', 'Please check this box if you want to proceed']}
                    value={this.state.isAccept}
                    checked={this.state.isAccept}
                    onChange={(e) => this.handleInputChange('isAccept', e.target.checked)}
                  />
                }
                label={
                  <div>
                    I accept the{' '}
                    <span
                      className="checkbox-item privacy-statement-link"
                      onClick={() => this.openLink('https://www.jazzpharma.com/privacy-statement/')}
                    >
                      Privacy Policy and Terms of Use
                    </span>
                    *
                  </div>
                }
              />
            </div>
            <div className="form-row">
              <ReCAPTCHA
                className="goog-recaptcha"
                sitekey={ReCAPTCHAKey}
                onChange={() => this.allowContinue()}
                onExpired={() => this.disableContinue()}
                onErrored={() => this.disableContinue()}
                required
              />
            </div>
            <div className="form-row button-group">
              <button
                type="submit"
                disabled={!this.state.canContinue}
                className="action-button primary-button"

              >
                {this.state.loading && <FontAwesomeIcon className="loading" icon={faSpinner} spin />}SUBMIT
                INFORMATION&nbsp; <FontAwesomeIcon className="arrow-right" icon="angle-right" />
              </button>
              <SiteLink to="/stay-connected/locate-a-sleep-specialist/" className="secondary-button">
                <button className="action-button">CANCEL</button>
              </SiteLink>
            </div>
          </ValidatorForm>
        </div>
        <EmailFinderComponent />
        <CommonModal isShowModal={this.state.showSuccessModal}>
          <div className="body-content">
            <h2 className="title">
              Thank you!
            </h2>
            <p className="content">
              Your profile has been updated.
            </p>
            <div className="action">
              <button className="button btn-primary" onClick={() => {
                  this.props.setCurrentPage(PROFILE_PAGES.searchProfile);
                }}>
                continue
              </button>
            </div>
          </div>
        </CommonModal>
        <LeavingSiteModal
          isShowModal={this.state.showLeavingSiteModal}
          onClose={this.handleHideModal}
          href={this.state.href}
        />
      </div>
    )
  }
}

UpdateYourProfileComponent.propTypes = {
  profile: PropTypes.object.isRequired,
  setCurrentPage: PropTypes.func.isRequired,
}

export default UpdateYourProfileComponent
