import React from 'react'
import PropTypes from 'prop-types'
import ReCAPTCHA from 'react-google-recaptcha'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'

import SiteLink from '@components/SiteLink'
import stateList from '@components/states'
import { PROFILE_PAGES } from '../profile.component'
import { getName } from '../profile.util'
import EmailFinderComponent from '@components/locate-sleep-specialist/email-finder/email-finder.component'
import { ValidatorForm, TextValidator, SelectValidator, CheckboxValidator } from '@components/validators'
import LeavingSiteModal from '@components/leaving-site-modal/leaving-site-modal.component'
import config from "@utils/config"
//TODO: hook up submit action
//TODO: fix styling
import { trackGa } from '@components/GoogleTracking'
import CommonModal from '@components/common-modal/common-modal.component'

import './remove-profile.component.scss'

const ReCAPTCHAKey = `${config.REACT_APP_GOOGLE_RECAPTCHA}`

class RemoveProfileComponent extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      StateLicense: '',
      StateLicenseExpirationDate: '',
      StateLicenseStateIssued: '',
      DEA: '',
      DEAExpirationDate: '',
      NpiNumber: '',
      isPrivacy: false,
      loading: false,
      showLeavingSiteModal: false,
      href: '',
      canContinue: false,
      showSuccessModal: false
    }
    this.onSubmitForm = this.onSubmitForm.bind(this)
    this.handleShowModalClicked = this.handleShowModalClicked.bind(this)
    this.handleHideModal = this.handleHideModal.bind(this)
  }

  allowContinue() {
    this.setState({ canContinue: true })
  }
  disableContinue() {
    this.setState({ canContinue: false })
  }

  handleInputChange(label, event) {
    const value = event.target ? event.target.value : event
    this.setState({
      [label]: value,
    })
  }

  onSubmitForm(event) {
    trackGa('remove,	click,	submit information - remove')
    event.preventDefault()
    this.setState({ ...this.state, loading: true })
    let postData = this.state
    fetch(`${config.REACT_APP_API_HOST}/physician-finder.php/DeletePhysician`, {
      method: 'POST',
      body: JSON.stringify(postData),
    })
      .then((res) => res.json())
      .then(() => {
        this.setState({ ...this.state, loading: false, showSuccessModal: true })
      })
  }

  handleHideModal() {
    this.setState({
      showLeavingSiteModal: false,
    })
  }

  handleShowModalClicked() {
    this.setState({
      showLeavingSiteModal: true,
    })
  }

  openLink(link) {
    this.setState(() => {
      return { href: link }
    }, this.handleShowModalClicked())
  }

  render() {
    return (
      <div className="remove-profile-page-wrapper">
        <div className="primary-container">
          <h1 className="main-page-header">Remove Your Profile</h1>
          <div className="customer-info-wrapper">
            <h4>{getName(this.props.profile)}</h4>
            <div className="customer-info-bottom">
              <p>{this.props.profile.Address1}</p>
              <p>{this.props.profile.Address2}</p>
              <p>
                {this.props.profile.City}, {this.props.profile.State} {this.props.profile.Zip}
              </p>
              <p>{this.props.profile.Phone}</p>
            </div>
          </div>
          <h4 className="title-bold font-change">Confirm Your Identity</h4>
          <p className="body-copy">
            In order to remove your profile from the Sleep Specialist directory, you must{' '}
            <span className="semi-bold">confirm your identity </span>by entering your State License and DEA numbers.
            (This information is private and will not be shared with anyone, including website users).
          </p>
          <ol>
            <li>
              We will send you an email to the address we have on file to validate you are authorized to remove this
              information from the directory.
            </li>
            <li>Click on the confirmation link included within the email to confirm your removal.</li>
          </ol>
          <p className="body-copy">
            Once this form is complete, we will validate the information you submitted and send you an email as soon as
            your information has been removed from the Sleep Specialist directory. If you have any questions, please
            contact us at{' '}
            <a className="inline-link" href="mailto:support@narcolepsylink.com">
              support@narcolepsylink.com
            </a>
            .
          </p>
          <ValidatorForm className="input-form" onSubmit={this.onSubmitForm}>
            <div className="form-group">
              <div className="input-group">
                <TextValidator
                  name="StateLicense"
                  className="input-normal"
                  label="State License Number*"
                  value={this.state.StateLicense}
                  onChange={(val) => this.handleInputChange('StateLicense', val)}
                  validators={['required', 'isNumbers']}
                  errorMessages={['Please fill out this field', 'Only numbers allowed']}
                  autoComplete="disabled"
                />
                <TextValidator
                  name="StateLicenseExpirationDate"
                  className="input-normal"
                  label="License Expiration Date*"
                  placeholder="MM/DD/YYYY"
                  value={this.state.StateLicenseExpirationDate}
                  onChange={(val) => this.handleInputChange('StateLicenseExpirationDate', val)}
                  validators={['required', 'isDate', 'isValidDate']}
                  errorMessages={[
                    'Please fill out this field',
                    'Please enter a valid date',
                    'Please enter a valid date',
                  ]}
                />
              </div>
              <div className=" input-medium">
                <SelectValidator
                  name="StateLicenseStateIssued"
                  className="input-medium"
                  value={this.state.StateLicenseStateIssued}
                  onChange={(val) => this.handleInputChange('StateLicenseStateIssued', val)}
                  label="State Issued*"
                  validators={['required']}
                  errorMessages={['Please fill out this field']}
                >
                  <option value="" disabled>
                    State Issued*
                  </option>
                  {stateList.map((item) => (
                    <option key={item.abbreviation} value={item.abbreviation}>
                      {item.name}
                    </option>
                  ))}
                </SelectValidator>
              </div>
            </div>
            <div className="form-group">
              <div className="input-group">
                <TextValidator
                  name="DEA"
                  className="input-normal"
                  label="DEA Number*"
                  value={this.state.DEA}
                  onChange={(val) => this.handleInputChange('DEA', val)}
                  validators={['required', 'isDea']}
                  errorMessages={['Please fill out this field', 'Please enter a valid DEA number']}
                />
                <TextValidator
                  name="DEAExpirationDate"
                  className="input-normal"
                  label="DEA Expiration Date*"
                  value={this.state.DEAExpirationDate}
                  placeholder="MM/DD/YYYY"
                  onChange={(val) => this.handleInputChange('DEAExpirationDate', val)}
                  validators={['required', 'isDate', 'isValidDate']}
                  errorMessages={[
                    'Please fill out this field',
                    'Please enter a valid date',
                    'Please enter a valid date',
                  ]}
                />
              </div>
            </div>
            <div className="form-row">
              <TextValidator
                name="NpiNumber"
                className="input-normal"
                label="NPI Number"
                value={this.state.NpiNumber}
                onChange={(val) => this.handleInputChange('NpiNumber', val)}
                validators={['isNumbers']}
                errorMessages={['Only numbers allowed']}
                autoComplete="disabled"
              />
            </div>
            <div className="checkbox-group">
              <FormControlLabel
                control={
                  <CheckboxValidator
                    name="accept"
                    validators={['required', 'isTrue']}
                    errorMessages={['Please check this box if you want to proceed', 'Please check this box if you want to proceed']}
                    value={this.state.isAccept}
                    checked={this.state.isAccept}
                    onChange={(e) => this.handleInputChange('isAccept', e.target.checked)}
                  />
                }
                label={
                  <div>
                    I accept the{' '}
                    <span
                      className="checkbox-item"
                      onClick={() => this.openLink('https://www.jazzpharma.com/privacy-statement/')}
                    >
                      Privacy Policy and Terms of Use
                    </span>
                    *
                  </div>
                }
              />
            </div>
            <div className="form-row">
              <ReCAPTCHA
                className="goog-recaptcha"
                sitekey={ReCAPTCHAKey}
                onChange={() => this.allowContinue()}
                onExpired={() => this.disableContinue()}
                onErrored={() => this.disableContinue()}
                required
              />
            </div>
            <div className="button-group">
              <button
                type="submit"
                disabled={!this.state.canContinue}
                className="action-button primary-button"

              >
                {this.state.loading && <FontAwesomeIcon className="loading" icon={faSpinner} spin />}SUBMIT
                INFORMATION&nbsp; <FontAwesomeIcon className="arrow-right" icon="angle-right" />
              </button>
              <SiteLink to="/stay-connected/locate-a-sleep-specialist/" className="secondary-button">
                <button className="action-button">CANCEL</button>
              </SiteLink>
            </div>
          </ValidatorForm>
        </div>
        <div className="email-finder-wrapper">
          <EmailFinderComponent />
        </div>
        <CommonModal isShowModal={this.state.showSuccessModal}>
          <div className="body-content">
            <h2 className="title">
              Thank you.
            </h2>
            <p className="content">
              Your profile has been removed.
            </p>
            <div className="action">
              <button className="button btn-primary" onClick={() => (this.handleCloseSuccess, this.props.goToPage(PROFILE_PAGES.searchProfile))}>
                continue
              </button>
            </div>
          </div>
        </CommonModal>
        <LeavingSiteModal
          isShowModal={this.state.showLeavingSiteModal}
          onClose={this.handleHideModal}
          href={this.state.href}
        />
      </div>
    )
  }
}

RemoveProfileComponent.propTypes = {
  profile: PropTypes.shape({
    First: PropTypes.string,
    Middle: PropTypes.string,
    Last: PropTypes.string,
    ProfessionalDesignation: PropTypes.string,
    Address1: PropTypes.string,
    Address2: PropTypes.string,
    City: PropTypes.string,
    State: PropTypes.string,
    Zip: PropTypes.string,
    Phone: PropTypes.string,
  }),
}

export default RemoveProfileComponent
