import React from 'react'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { PROFILE_PAGES } from '../profile.component'

import { getName } from '../profile.util'

import './profile-card.component.scss'

const ProfileCardComponent = props => {

  const onUpdateClick = () => {
    props.setProfile(props.profileContent);
    props.setCurrentPage(PROFILE_PAGES.updateProfile);
  }

  return (
    <div className="primary-card profile-card">
      <div className="profile-info">
        <div className="user-name">
          <div className="row-name">
            <label className="number">{props.index}</label>
            <p>{getName(props.profileContent)}</p>
          </div>
          {props.profileContent.isProverAccept && (
            <div className="provider-accept desktop-version">
              <div className="check-mark">
                <FontAwesomeIcon icon="check" />
              </div>
              <p>Pediatric patients welcome</p>
            </div>
          )}
        </div>
        <div className="contact-info">
          <div className="contact-address">
            <p>
              {props.profileContent.Address1} {props.profileContent.Address2}
            </p>
            <p>{`${props.profileContent.City}, ${props.profileContent.State} ${props.profileContent.Zip}`}</p>
            <p className="contact-phone mobile-version">{props.profileContent.Phone}</p>
            <p className="distance">{`${Math.round(props.profileContent.DistanceFromRequest)} miles away`}</p>
          </div>
          <p className="contact-phone desktop-version">{props.profileContent.Phone}</p>
        </div>
        {props.profileContent.isProverAccept && (
          <div className="provider-accept mobile-version">
            <div className="check-mark">
              <FontAwesomeIcon icon="check" />
            </div>
            <p>Pediatric patients welcome</p>
          </div>
        )}
      </div>
      <a className="next-arrow" onClick={() => onUpdateClick()}>
        <label>Update</label>
        <FontAwesomeIcon className="arrow-right" icon="angle-right" />
      </a>
    </div>
  )
}

ProfileCardComponent.propTypes = {
  profileContent: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  setCurrentPage: PropTypes.func.isRequired,
  setProfile: PropTypes.func.isRequired,
}

export default ProfileCardComponent
