import React, {useState} from 'react'
import Layout from '@components/layout/Layout'

import SearchProfileComponent from '@components/profile/search-profile/search-profile.component'
import SelectProfileComponent from '@components/profile/select-profile/select-profile.component'
import UpdateProfileComponent from '@components/profile/update-profile/update-profile.component'
import RemoveProfileComponent from '@components/profile/remove-profile/remove-profile.component'
import { PROFILE_PAGES } from '@components/profile/profile.component'


const UpdateSleepSpecialistDirectory = ({ location }) => {
  const [currentPage, setCurrentPage] =  useState(PROFILE_PAGES.searchProfile)
  const [search, setSearch] =  useState("")
  const [profile, setProfile] =  useState("")

  return (
    <Layout addedClass="page-find-sleep-specialist" location={location}>
        {currentPage === PROFILE_PAGES.searchProfile ?
          <SearchProfileComponent setCurrentPage={setCurrentPage} setSearch={setSearch} /> 
        : currentPage === PROFILE_PAGES.selectProfile ?
          <SelectProfileComponent search={search} setCurrentPage={setCurrentPage} setProfile={setProfile}/>
        : currentPage === PROFILE_PAGES.updateProfile ?
          <UpdateProfileComponent profile={profile} setCurrentPage={setCurrentPage}/>
        : currentPage === PROFILE_PAGES.removeProfile ?
          <RemoveProfileComponent profile={profile} />
        : null}
    </Layout>
  ) 
}

export default UpdateSleepSpecialistDirectory
