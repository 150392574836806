import React from 'react'
import PropTypes from 'prop-types'

import ProfileCardComponent from './profile-card.component'
import EmailFinderComponent from '@components/locate-sleep-specialist/email-finder/email-finder.component'
import { PROFILE_PAGES } from '../profile.component'
import config from "@utils/config"
import { win } from '@src/app-utils';

import './select-profile.component.scss'

class SelectProfileComponent extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isProvider: false,
      profiles: [],
      loading: true
    }
  }

  componentDidMount() {
    this.getProfiles()
  }
  getProfiles() {
    const params = {
      Lat: this.props.search.Lat,
      Lng: this.props.search.Lng,
      GoogleAnalyticsCampaignCode: 'UA-1233211', //TODO: correct google analytics code
      ItemCount: 30,
      PageNumber: 1
    }
    try {
      fetch(`${config.REACT_APP_API_HOST}/physician-finder.php/RecordSearch?${this.objToQueryString(params)}`)
        .then(res => res.json())
        .then(data => {
          this.setState({ ...this.state, loading: false, profiles: data.Results })
        })
    } catch (err) {
      // console.log(err)
    }
  }

  objToQueryString(obj) {
    const keyValuePairs = []
    for (const key in obj) {
      keyValuePairs.push(encodeURIComponent(key) + '=' + encodeURIComponent(obj[key]))
    }
    return keyValuePairs.join('&')
  }

  handleInputChange(label, value) {
    this.setState({
      [label]: value
    })
  }

  render() {
    return (
      <div className="select-your-profile">
        <div className="primary-container">
          <h1 className="main-page-header">Select Your Profile</h1>
          <p className="search-results">
            Results near <b>{win && localStorage.getItem('placeName')}</b>{' '}
            <a
              className="link-text"
              onClick={() => {
                this.props.setCurrentPage(PROFILE_PAGES.searchProfile)
              }}
            >
              change location
            </a>
          </p>
        </div>
        <div className="primary-container">
          <div className="profile-list">
            <div className="loader-wrapper">{this.state.loading ? <div className="load" /> : null}</div>
            <div className="load" />
            {this.state.profiles.map((profile, index) => (
              <ProfileCardComponent
                key={index}
                profileContent={profile}
                setProfile={this.props.setProfile}
                setCurrentPage={this.props.setCurrentPage}
                index={index + 1}
              />
            ))}
          </div>
        </div>
        <EmailFinderComponent />
      </div>
    )
  }
}

SelectProfileComponent.propTypes = {
  setCurrentPage: PropTypes.func.isRequired,
  setProfile: PropTypes.func.isRequired,
  search: PropTypes.object.isRequired
}

export default SelectProfileComponent
