import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import './search-box.component.scss'

export class SearchBox extends Component {
  constructor() {
    super()

    this.state = {
      searchDisabled: true
    }
  }

  componentDidMount() {
    // the autocomplete resutls persist when coming back and forth from the page so they need to be cleared when returning
    //so when a user hits enter, it selects the correct top result
    let autocompleteResults = document.getElementsByClassName('pac-container')
    if (autocompleteResults.length > 0) {
      for (let result of autocompleteResults) {
        result.innerHTML = ''
      }
    }
    let input = document.getElementById('pac-input')
    let options = {
      types: ['(regions)'],
      componentRestrictions: { country: 'us' }
    }

    let autocomplete = new window.google.maps.places.Autocomplete(input, options)

    new window.google.maps.event.addListener(autocomplete, 'place_changed', () => {
      var place = autocomplete.getPlace()

      if (place.geometry) {
        localStorage.setItem('placeName', place.name)
        this.setCoordinates(place.geometry.location)
      } else {
        this.selectFirstResult()
      }
    })
  }

  selectFirstResult() {
    let autocompleteResults = document.getElementsByClassName('pac-item')
    if (!autocompleteResults.length > 0) {
      return
    }

    let firstResult = autocompleteResults[0].textContent
    var geocoder = new window.google.maps.Geocoder()
    geocoder.geocode({ address: firstResult }, (results, status) => {
      if (status == window.google.maps.GeocoderStatus.OK) {
        this.setCoordinates(results[0].geometry.location, results[0].address_components[0].long_name)
      }
    })
  }

  setCoordinates(location, placeName) {
    this.setState({
      searchDisabled: false
    })

    let bounds = new window.google.maps.LatLngBounds()
    bounds.extend(location)
    let coordinatesString = JSON.stringify(bounds)
    let coordinatesObject = JSON.parse(coordinatesString)
    const Lat = (coordinatesObject.north + coordinatesObject.south) / 2
    const Lng = (coordinatesObject.east + coordinatesObject.west) / 2
    this.props.newLocation({ Lat: Lat, Lng: Lng, place: placeName })

    this.props.runSearch()
  }

  render() {
    return (
      <div className="search-component-wrapper">
        <div className="search-input" id="pac-container" style={{ height: '60px' }}>
          <input id="pac-input" type="text" placeholder="ZIP CODE OR CITY, STATE" style={{ height: '60px' }} />
        </div>
        <div className="search-button-wrapper">
          <button className="pp-search-button" disabled={this.state.searchDisabled} onClick={this.props.runSearch}>
            Search<span className="search-arrow">
              <FontAwesomeIcon className="arrow-right" icon="angle-right" />
            </span>
          </button>
        </div>
      </div>
    )
  }
}

export default SearchBox

SearchBox.propTypes = {
  runSearch: PropTypes.func,
  newLocation: PropTypes.func
}
