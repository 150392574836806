export const specialityList = [
  { id: 'Sleep Specialist', name: 'Sleep Specialist' },
  { id: 'Psychiatrist', name: 'Psychiatrist' },
  { id: 'Neurologist', name: 'Neurologist' },
  { id: 'Pulmonologist', name: 'Pulmonologist' },
  { id: 'Primary Care Physician', name: 'Primary Care Physician' },
  { id: 'Internal Medicine Physician', name: 'Internal Medicine Physician' },
  { id: 'Nurse Practitioner/Physician Assistant', name: 'Nurse Practitioner/Physician Assistant' },
  { id: 'Sleep Technologist', name: 'Sleep Technologist' },
  { id: 'Other Healthcare Professional', name: 'Other Healthcare Professional' }
]

export const professionalDesignationList = [
  { id: 'DO', name: 'DO' },
  { id: 'MD', name: 'MD' },
  { id: 'NP', name: 'NP' },
  { id: 'PA', name: 'PA' },
  { id: 'PharmD', name: 'PharmD' },
  { id: 'PhD', name: 'PhD' },
  { id: 'RN', name: 'RN' },
  { id: 'RPH', name: 'RPH' },
  { id: 'Other', name: 'Other' },
]

export const certifyCheckbox = {
  id: 'checkbox1',
  label: 'I certify that I have been trained to diagnose and differentiate sleep disorders.',
  required: true,
  className: 'checkbox-item'
}

export function getOptionIsSelected(optionValue, serverValue) {
  let compare1
  let compare2

  if(optionValue) {
    compare1 = optionValue.toLowerCase()
  }

  if(serverValue) {
    compare2 = serverValue.toLowerCase()
  }

  return compare1 == compare2 ? 'selected' : ''
}

export function getListValue(list, serverValue) {
  for(let i = 0; i < list.length; i++) {
    let value = list[i].id || list[i].abbreviation
    if(getOptionIsSelected(value, serverValue) === 'selected') {
      return value
    }
  }
}

export function getName(profile) {
  const middle = profile.Middle || ''
  return `${profile.First} ${middle} ${profile.Last}, ${profile.ProfessionalDesignation}`
}